import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { selectTask } from "../routes/Tasks/tools";
import { emit } from "../utils/sockets";

export interface LogRow {
  started_at: string;
  length_secs: number;
  completed_at: string;
  error_at: string;
  metadata?: string;
  current_state: "complete" | "started" | "root_started" | "error" | "running";
}
export type TaskDesc = {
  name: string;
  last_started_at?: string;
  is_paused?: boolean;
  isRoot?: boolean;
  sql?: string;
  logs?: LogRow[];
};

type TaskEdge = {
  label?: string;
  name: string;
  status: string;
  depth: number;
  from: string;
  to: string;
};

type TaskNode = {
  id: string;
  name: string;
  depth: number;
  status: string;
};

export type TaskGraph = {
  edges: TaskEdge[];
  nodes: TaskNode[];
  id: string;
};

export interface TasksState {
  tasksListModal: {
    open: boolean;
  };
  taskDetailModal: {
    tab: "logs" | "chart" | "sql";
    open: boolean;
    taskName: string;
  };
  tasks: {
    error: string;
    data: TaskDesc[];
  };
  taskSummaryLogs: Record<string, SummaryLog>;
  activeTask: {
    taskName: string;
    error: string;
    isRunning: boolean;
    progress: {
      steps: number;
      completed: number;
    };
    treeGraph: TaskGraph;
  };
}

type SummaryLog = {
  task_name: string;
  last_completed_at: string;
  last_started_at: string;
  completed_count?: number;
  errors_count?: number;
  average_runtime_secs?: number;
};

const initialState: TasksState = {
  tasksListModal: {
    open: false,
  },
  taskDetailModal: {
    tab: "logs",
    open: false,
    taskName: "",
  },
  tasks: {
    error: "",
    data: [],
  },
  taskSummaryLogs: {},
  activeTask: {
    taskName: "",
    error: "",
    isRunning: false,
    progress: {
      steps: 0,
      completed: 0,
    },
    treeGraph: {
      edges: [],
      nodes: [],
      id: "",
    },
  },
};
type Res<T> = { data: T; error: null } | { data: null; error: string };
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const counterSlice = createSlice({
  name: "tasks",
  initialState: { ...initialState },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    toggleTaskList: (state, action: PayloadAction<boolean | undefined>) => {
      state.tasksListModal.open = action.payload ?? !state.tasksListModal.open;
    },
    toggleTaskDetails: (state, action: PayloadAction<string>) => {
      state.taskDetailModal.open = action.payload !== "";
      state.taskDetailModal.taskName = action.payload;
    },
    setDetailsTab: (
      state,
      action: PayloadAction<TasksState["taskDetailModal"]["tab"]>,
    ) => {
      state.taskDetailModal.tab = action.payload;
    },
    setActiveTask: (state, action: PayloadAction<string>) => {
      const taskName = action.payload;
      console.log("EMIT", taskName);
      selectTask(taskName);
      state.activeTask.taskName = taskName;
    },
    runTask: (state, action: PayloadAction<string>) => {
      const { taskName, isRunning } = state.activeTask;
      if (isRunning) return;
      emit("tasks", "run", { taskName });
      state.activeTask.isRunning = true;
      state.activeTask.error = "";
    },
    setTasks: (state, action: PayloadAction<TaskDesc[]>) => {
      state.tasks.data = action.payload;
    },
    setRootTasks: (state, action: PayloadAction<TaskDesc[]>) => {
      const roots = action.payload;
      state.tasks.data = state.tasks.data
        .map(function (task) {
          let { name } = task;
          let data = roots.find((r) => r.name === name);
          if (!data) {
            return task;
          }
          return { ...task, ...data, isRoot: true };
        })
        .sort(function (a, b) {
          return a.last_started_at && b.last_started_at
            ? 0
            : a.last_started_at
              ? -1
              : 1;
        });
    },
    setTaskLogs: (state, action: PayloadAction<TaskDesc>) => {
      let exists = 0;
      const logDetails = action.payload;
      const data = state.tasks.data.map(function (task) {
        if (task.name === logDetails.name) {
          exists++;
          return { ...task, ...logDetails };
        }
        return task;
      });
      if (!exists) {
        data.push(logDetails);
      }
      state.tasks.data = data;
    },
    setTaskSQL: (state, action: PayloadAction<TaskDesc>) => {
      let exists = 0;
      const logDetails = action.payload;
      const data = state.tasks.data.map(function (task) {
        if (task.name === logDetails.name) {
          exists++;
          return { ...task, ...logDetails };
        }
        return task;
      });
      if (!exists) {
        data.push(logDetails);
      }
      state.tasks.data = data;
    },
    setTaskStatus: (
      state,
      action: PayloadAction<{
        nodes: TaskNode[];
        id: string;
        edges: TaskEdge[];
        status: string;
      }>,
    ) => {
      const data = action.payload;
      state.activeTask.isRunning = !["RUN", "COMPLETE", "ERROR"].includes(
        data.status,
      );
      drawTaskTree(state, action);
    },
    handleErrors: (state, action: PayloadAction<any>) => {
      const res = action.payload;
      let { error } = res;
      if (["status", "run"].includes(res.request)) {
        state.activeTask.isRunning = false;
      }
      state.activeTask.error = error;
    },
    toggleRootTaskPaused: (state, action: PayloadAction<TaskDesc>) => {
      const { name, is_paused } = action.payload;
      state.tasks.data = state.tasks.data.map(function (task) {
        if (task.name === name) {
          return { ...task, is_paused };
        }
        return task;
      });
    },
    setCalculatedTree: (
      state,
      action: PayloadAction<{
        nodes: TaskNode[];
        id: string;
        edges: TaskEdge[];
        status: string;
      }>,
    ) => {
      drawTaskTree(state, action);
    },
    addTaskSummaries(state, action: PayloadAction<SummaryLog[]>) {
      action.payload.forEach((l) => {
        state.taskSummaryLogs[l.task_name] = l;
      });
    },
  },
  extraReducers: (builder) => {},
});

function drawTaskTree(
  state: TasksState,
  action: PayloadAction<{
    nodes: TaskNode[];
    id: string;
    edges: TaskEdge[];
    status: string;
  }>,
) {
  const data = action.payload;
  const { nodes } = data;
  state.activeTask.treeGraph = data;
  state.activeTask.progress = {
    steps: nodes.filter(
      (n) => !["IS_ALREADY_COMPLETE", "NOT_TO_BE_RUN"].includes(n.status),
    ).length,
    completed: nodes.filter((n) =>
      ["RUN", "COMPLETE", "ERROR"].includes(n.status),
    ).length,
  };
}

export const {
  setActiveTask,
  toggleTaskList,
  toggleTaskDetails,
  runTask,
  setTasks,
  setRootTasks,
  setTaskLogs,
  setCalculatedTree,
  setTaskStatus,
  setTaskSQL,
  handleErrors,
  toggleRootTaskPaused,
  addTaskSummaries,
} = counterSlice.actions;

export default counterSlice.reducer;

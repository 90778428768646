import {io} from "socket.io-client"

const socket = io();

export default socket

export function emit<Req = any>(resource: string, action:string, request?:Req){
    const data = { resource, action, request };
    socket.emit('api', data)
}

export function subscribe<T = any>(eventName: string, fn: (res: T)=>void){
    socket.on(eventName, fn)
    return ()=>{
        socket.off(eventName, fn)
    }
}
import React, {
  PropsWithChildren,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { Redirect, Link, useLocation, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { api } from "../utils/api";
import {
  setUser,
  setMFA,
  logout,
  completeMFALogin,
  requestMFAReset,
} from "../redux/userSlice";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import socket from "../utils/sockets";

type Provider = "google" | "microsoft";
const LOGOS: Record<Provider, string> = {
  google: "https://developers.google.com/identity/images/g-logo.png",
  microsoft:
    "https://docs.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.png",
};
const joinStyles = { height: "40px", minWidth: "200px" };
const BTN_STYLES: Record<Provider, React.CSSProperties> = {
  google: { ...joinStyles, background: "white", color: "black" },
  microsoft: { ...joinStyles, background: "blue", borderColor: "blue" },
};
function Logo(props: { provider: Provider }) {
  const { provider } = props;
  return (
    <img
      alt="logo"
      style={{ height: "100%", float: "left", width: "25px" }}
      src={LOGOS[provider]}
    />
  );
}
function LoginBtn(
  props: PropsWithChildren<{
    provider: Provider;
    onClick: (provider: Provider) => any;
  }>,
) {
  const { provider, children, onClick } = props;
  const style = BTN_STYLES[provider];
  return (
    <Button
      variant="contained"
      color="primary"
      style={style}
      size="large"
      endIcon={<Logo provider={provider} />}
      onClick={() => onClick(provider)}
    >
      {children}
    </Button>
  );
}

interface AsyncState {
  loading: boolean;
  error?: string;
}

export function Login() {
  const [{ loading, error }, setAsyncState] = useState<AsyncState>({
    loading: false,
  });
  const getURL = useCallback(
    async function (provider: Provider) {
      try {
        setAsyncState({ loading: true });
        const { error, data } = await api({
          resource: "auth",
          action: "getOAuthURL",
          request: { provider },
        });
        if (error || !data) {
          throw new Error(error || "Unknown Error");
        }
        window.location.href = data;
      } catch (e: any) {
        setAsyncState({ loading: false, error: e?.message });
      }
    },
    [setAsyncState],
  );
  return (
    <Centered>
      <Grid
        container
        direction="column"
        spacing={3}
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item>
          <Box textAlign="center">
            <h1>
              <span className="fa fa-lock" /> TCC Analytics Login
            </h1>
            <p>
              Choose your login provider: For most TCC emails this will be
              Microsoft.
            </p>
            {error && <Alert severity="error">Error: {error}</Alert>}
          </Box>
        </Grid>
        <Grid item xs={12}></Grid>
        {loading ? (
          <Grid item xs={6}>
            <h5>Loading ...</h5>
          </Grid>
        ) : (
          <>
            <Grid item>
              <LoginBtn onClick={getURL} provider="google">
                Google
              </LoginBtn>
            </Grid>
            <Grid item>
              <LoginBtn onClick={getURL} provider="microsoft">
                Microsoft
              </LoginBtn>
            </Grid>
          </>
        )}
      </Grid>
      <LoginRedirect />
    </Centered>
  );
}

function Centered(props: PropsWithChildren<{}>) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Box p={3}>
          <Paper elevation={2}>
            <Box p={3}>{props.children}</Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}

const KEY = "redirectLocation";
interface LocationDescriptor {
  pathname: string;
  search?: string;
  hash?: string;
}

function getRedirect(): LocationDescriptor {
  const DEF_REDIRECT = { pathname: "/editor" };
  try {
    const location = JSON.parse(localStorage.getItem(KEY) || "{}");
    const { pathname } = location;
    if (
      ["/login", "/logout"].includes(pathname) ||
      typeof pathname !== "string"
    ) {
      return DEF_REDIRECT;
    }
    return location;
  } catch (error) {
    return DEF_REDIRECT;
  }
}

export function LoginRedirect() {
  const isAuthed = useAppSelector((state) => !!state.user?.user);
  const location = useLocation();
  useEffect(function () {
    if (["/login", "/logout"].includes(location.pathname)) {
      return;
    }
    localStorage.setItem(KEY, JSON.stringify(location));
  }, []);
  if (!isAuthed) {
    return <Redirect to="/login" />;
  }
  return <Redirect to={getRedirect()} />;
}

function oAuthLogin(provider: Provider, code: string) {
  return api({
    resource: "auth",
    action: "oAuthLogin",
    request: {
      provider,
      code,
    },
  });
}

export function OAuthCallback() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { provider } = useParams<{ provider: Provider }>();
  const mfa = useAppSelector(function (state) {
    return state.user.mfa;
  });
  const [{ loading, error }, setAsyncState] = useState<AsyncState>({
    loading: false,
  });
  const logUserIn = async () => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    if (!code) {
      setAsyncState({
        error: "No authentication code provided",
        loading: false,
      });
      return;
    }
    const { error, data } = await oAuthLogin(provider, code);
    if (error) {
      setAsyncState({ loading: false, error });
      dispatch(setUser(null));
      return;
    }
    return dispatch(setMFA(data));
  };
  useEffect(function () {
    logUserIn();
  }, []);

  if (mfa) {
    return <Redirect to="/auth/mfa" />;
  }
  if (loading) {
    return (
      <Centered>
        <h2>LOADING ....</h2>
      </Centered>
    );
  }
  if (error) {
    return (
      <Centered>
        <h2>Error While Logging In</h2>
        {error && <p>{error}</p>}
        <Link to="/login">
          <Button variant="contained" color="primary">
            Try Again
          </Button>
        </Link>
      </Centered>
    );
  }
  return (
    <Centered>
      <h2>LOADING ....</h2>
    </Centered>
  );
}

export function AuthMFA() {
  const { user, mfa, error, requestedReset } = useAppSelector(function (state) {
    const { user, mfa, error, requestMFAReset } = state.user;
    const requestedReset =
      requestMFAReset.status === "loading"
        ? "Requesting Please wait..."
        : requestMFAReset.error
          ? "Request Failed"
          : requestMFAReset.complete
            ? "Request Submitted"
            : "";
    return { user, mfa, error: error || undefined, requestedReset };
  });
  const dispatch = useAppDispatch();
  const [code, setCode] = useState("");
  useEffect(
    function () {
      if (code.length === 6 && /^\d+$/.test(code)) {
        dispatch(completeMFALogin(code));
      }
    },
    [code],
  );

  const completeLogin: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    dispatch(completeMFALogin(code));
    return;
  };

  if (!mfa) {
    return <Redirect to="/login" />;
  }
  if (user) {
    return <Redirect to="/app/editor" />;
  }
  if (requestedReset) {
    return (
      <Centered>
        <Header error={error} />
        <h5>{requestedReset}</h5>
        <p className="font-14">
          Your request will reach an Admin. Maybe teams message to Michael O
          Toole or a member of the DIC team or a member of the marketing team to
          get it approved faster :)
        </p>
        <Link to="/login">
          <Button color="primary" variant="contained">
            Back
          </Button>
        </Link>
      </Centered>
    );
  }
  return (
    <Centered>
      <form onSubmit={completeLogin}>
        <Grid container direction="column" spacing={2}>
          <Header error={error} />
          <QRView />

          <Grid item>
            <h5>Complete Login</h5>
            <p className="font-14">
              Insert the 6 digit code provided in your Google Authenicator App.
            </p>
            <TextField
              autoFocus
              fullWidth
              label="Code"
              placeholder="123456"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={code.length !== 6}
            >
              Submit
            </Button>
          </Grid>

          <Grid item>
            <MFAReset />
          </Grid>
        </Grid>
      </form>
    </Centered>
  );
}

function MFAReset() {
  const mfa = useAppSelector(function (state) {
    return state.user.mfa;
  });
  const dispatch = useAppDispatch();
  const requestReset = () => {
    if (!mfa?.mfaId) {
      return;
    }
    dispatch(requestMFAReset(mfa.mfaId));
  };
  if (mfa?.dataURL) {
    return null;
  }
  return (
    <div className="pull-right">
      To Request MFA reset click{" "}
      <span style={ACHOR_S} onClick={requestReset}>
        here
      </span>
    </div>
  );
}

function Header(props: { error?: string }) {
  const { error } = props;
  return (
    <Grid item container>
      <Grid item xs={12}>
        <h2>
          <span className="fa fa-lock" /> 2-Factor Authentication
        </h2>
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Alert severity="error">Error: {error}</Alert>
        </Grid>
      )}
    </Grid>
  );
}

const ACHOR_S = {
  color: "blue",
  underline: "blue",
  cursor: "pointer",
};

function QRView() {
  const mfa = useAppSelector(function (state) {
    return state.user.mfa;
  });
  if (!mfa) {
    return null;
  }
  const { dataURL, base32 } = mfa;
  if (!dataURL || !base32) {
    return null;
  }
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <p className="font-14">Please set up your 2-factor authentication.</p>
      </Grid>
      <Grid item container>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <img alt={base32} src={dataURL} />
        </Grid>
        <Grid item xs={2} />
        <Grid item>
          <small>
            {" "}
            OR <code>{base32}</code>
          </small>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h5>Setup Instructions</h5>
        <p className="font-14">
          <ol>
            <li>Download the Google Authenticator App to your phone.</li>
            <li>Scan the image above using the app.</li>
            <li>Enter the 6 digit code from your phone and submit</li>
          </ol>
        </p>
        <p>
          <small>
            Take care as this is the only time you will receive your
            authentication code pairing. If you require a desktop app instead of
            a phone (this is not a good idea unless really needed) please use
            this{" "}
            <a href="https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai/related?hl=en">
              link
            </a>
          </small>
        </p>
      </Grid>
    </React.Fragment>
  );
}

export function Logout() {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(function (state) {
    return state.user.logout;
  });
  useEffect(function () {
    dispatch(logout());
  }, []);
  return (
    <Centered>
      <h3>{status === "loading" ? "Logging Out" : "Logout complete!"}</h3>
      <Link to="/login">
        <Button variant="contained" color="primary">
          {" "}
          Login Again{" "}
        </Button>
      </Link>
    </Centered>
  );
}

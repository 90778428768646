import React, { PropsWithChildren } from 'react';
import { createTheme, ThemeProvider as Provider } from '@material-ui/core/styles';

export const COLORS = {
  PEACH: '#f9b69f',
  PINK: '#f5b7c2', // Sewing
  PINK_DARK: '#dd4d77',
  MINT: '#c8e5e2', // knitting
  BLUE: '#a2d8ef',
  YELLOW: '#ffe37e', // plotting
  ///// BI-DASH
  PINK_MEDIUM: '#ff885f',
  BLUE_GREY: '#6f95a5',
  MINT_GREY: '#98b1ae'
}
// https://material-ui.com/customization/default-theme/
export const theme = createTheme({
  typography: {
    fontFamily: '"futura-pt", "Roboto", "Helvetica", "Arial", sans-serif'
  },
  palette: {
    background: {
      // @ts-ignore
      error: COLORS.PINK_DARK,
    },
    primary: {
      main: COLORS.PEACH
    },
    secondary: {
      main: COLORS.MINT
    },
    warning: {
      main: COLORS.YELLOW
    },
    error: {
      main: COLORS.PINK_DARK
    },
    info: {
      main: COLORS.BLUE
    }
  },
});

export function ThemeProvider(props: PropsWithChildren<{}>) {
  return <Provider theme={theme}>
    {props.children}
  </Provider>
}

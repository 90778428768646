import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ObjectId from "bson-objectid";
import { debounce } from "debounce";
import { RootState } from "./store";
import { api } from "../utils/api";
//@ts-ignore
import ejs from "ejs/ejs.min.js";
import _ from "lodash";

const cacheTabs = debounce(async function cacheTabs(tab: QueryTab) {
  await api({
    resource: "queryTabs",
    action: "cache",
    request: tab,
  });
}, 1000 * 1.5);

export const Connections = ["REDSHIFT_MAKERIST", "POSTGRES_MAKERIST"] as const;

export type ConnectionType = (typeof Connections)[number];
type Row = Record<string, any>;

export interface LogRow {
  started_at: string;
  length_secs: number;
  completed_at: string;
  error_at: string;
}
export type TaskDesc = {
  name: string;
  last_started_at?: string;
  is_paused?: boolean;
  isRoot?: boolean;
  sql?: string;
  logs?: LogRow[];
};

export interface Query {
  _id: string;
  sql: string;
  adminTitle: string;
  tags: string[];
  connectionId: ConnectionType;
  ownerId?: string;
  updatedAt: string;
  createdAt: string;
}

export interface QueryTab {
  _id: string;
  title?: string;
  connectionId: ConnectionType;
  value: string;
  error: string;
  loading: boolean;
  queryRunId?: string;
  queryId?: string;
}

function TAB(): QueryTab {
  return {
    _id: new ObjectId().toHexString(),
    connectionId: "REDSHIFT_MAKERIST",
    value: "SELECT * FROM dims.dates LIMIT 10",
    error: "",
    loading: false,
  };
}
export interface Connection {
  id: string;
  name: string;
  gitrepo?: string;
  color?: string;
}

type QueryRun = any[];
export interface TasksState {
  runningQueriesModal: {
    open: boolean;
  };
  schemaSidebar: {
    open: boolean;
  };
  listQueriesModal: {
    open: boolean;
  };
  queryEditModal: {
    open: boolean;
    query: Query;
  };
  queries: {
    error: string;
    loading: boolean;
    data: Query[];
  };
  tabs: {
    data: QueryTab[];
  };
  runs: Record<string, QueryRun>;
  schemas: Record<string, ParsedSchema>;
  connections: Connection[];
  activeQuery: {
    error: string;
    isRunning: boolean;
    tabId: string;
  };
}
const DEFAULT_TAB = TAB();
const initialState: TasksState = {
  connections: [
    {
      id: "REDSHIFT_MAKERIST",
      name: "Redshift DWH",
      gitrepo: "https://github.com/makerist/bi-reloaded/",
    },
    {
      id: "POSTGRES_MAKERIST",
      name: "Makerist Prod Db",
    },
  ],
  runningQueriesModal: {
    open: false,
  },
  schemaSidebar: {
    open: false,
  },
  listQueriesModal: {
    open: false,
  },
  queryEditModal: {
    open: false,
    query: {
      _id: "",
      adminTitle: "",
      tags: [],
      connectionId: "REDSHIFT_MAKERIST",
      sql: "",
      createdAt: new Date(0).toISOString(),
      updatedAt: new Date(0).toISOString(),
    },
  },
  queries: {
    error: "",
    loading: false,
    data: [],
  },
  tabs: {
    data: [],
  },
  runs: {},
  schemas: {},
  activeQuery: {
    error: "",
    isRunning: false,
    tabId: DEFAULT_TAB._id,
  },
};

export function selectTabById(state: RootState, _id: string) {
  const { tabs } = state.queries;
  return tabs.data.find((t: QueryTab) => t._id === _id);
}

export const selectActiveTab = (state: RootState) => {
  const { activeQuery } = state.queries;
  return selectTabById(state, activeQuery.tabId);
};

type Res<T> = { data: T; error: null } | { data: null; error: string };

function template(sql: string, options: any = {}): string {
  return ejs.render(sql, {});
}

async function getQuery(queryId: string): Promise<Res<Query>> {
  let res = await api({
    resource: "queries",
    action: "get",
    request: { queryId },
  });
  if (res.data) {
    res.data = mapQuery(res.data);
  }
  return res;
}
export const loadTab = createAsyncThunk<Res<QueryTab[]>, string>(
  "queries/loadTab",
  async function (tabId: string) {
    const res = await api({
      resource: "queryTabs",
      action: "get",
      request: {
        _id: tabId,
      },
    });
    return res;
  },
);
export const loadTabCache = createAsyncThunk<QueryTab[], void>(
  "queries/loadTabCache",
  async function () {
    const { data: tabs } = await api({
      resource: "queryTabs",
      action: "get",
      request: {},
    });
    const output = tabs || [TAB()];
    if (!output.length) {
      output.push(TAB());
    }
    return output;
  },
);
export const loadQuery = createAsyncThunk<Res<Query>, string>(
  "queries/loadQuery",
  getQuery,
);
export const selectQuery = createAsyncThunk<Res<Query>, string>(
  "queries/selectQuery",
  getQuery,
);
function mapQuery(query: Query): Query {
  switch (query.connectionId as string) {
    case "redshift":
      query.connectionId = "REDSHIFT_MAKERIST";
      break;
    case "postgres_makerist":
    case "makerist":
      query.connectionId = "POSTGRES_MAKERIST";
      break;
    default:
      break;
  }
  return query;
}
export const loadQueries = createAsyncThunk<Res<Query[]>, void>(
  "queries/loadQueries",
  async () => {
    const res = await api({
      resource: "queries",
      action: "get",
      request: {},
    });
    if (res.data) {
      res.data = res.data.map(mapQuery);
    }
    return res;
  },
);
export const saveQuery = createAsyncThunk<Res<Query>, Partial<Query>>(
  "queries/saveQuery",
  async (query) => {
    const res = await api({
      resource: "queries",
      action: !query._id ? "create" : "update",
      request: query,
    });
    return res;
  },
);

export const deleteQuery = createAsyncThunk<Res<void>, string>(
  "queries/deleteQuery",
  async (queryId: string) => {
    const res = await api({
      resource: "queries",
      action: "delete",
      request: {
        _id: queryId,
      },
    });
    return res;
  },
);

export const runQuery = createAsyncThunk<Res<Row[]>, QueryTab>(
  "queries/runQuery",
  async (tab: QueryTab, thunkAPI) => {
    const { requestId } = thunkAPI;
    const { value, connectionId } = tab;
    const req = {
      resource: "dbs",
      action: "query",
      request: {
        dbId: connectionId,
        query: `/*${requestId}*/\n` + template(value),
      },
    };
    const res = await api(req);
    return res;
  },
);

export const downloadCSV = createAsyncThunk<Res<null>, QueryTab>(
  "queries/downloadCSV",
  async (tab, thunkAPI) => {
    const { requestId } = thunkAPI;
    const { value, connectionId } = tab;
    const { error, data } = await api({
      resource: "dbs",
      action: "toFile",
      request: {
        dbId: connectionId,
        query: `/*${requestId}*/\n` + template(value),
      },
    });
    if (error) {
      return { error, data: null };
    }
    const url = `/api/v0.1?resource=dbs&action=fromFile&request={"id":"${data}"}`;
    window.open(url);
    return { error, data: null };
  },
);
export const cancelRun = createAsyncThunk<void, QueryTab>(
  "queries/cancel",
  async (tab) => {
    const { queryRunId, connectionId } = tab;
    await api({
      resource: "dbs",
      action: "cancel",
      request: {
        dbId: connectionId,
        queryId: queryRunId,
      },
    });
  },
);
export const loadSchema = createAsyncThunk<Res<SchemaRow[]>, string>(
  "schemas/load",
  (connectionId) => {
    const SQL = `
        SELECT 
            table_schema,
            table_name,
            column_name,
            data_type,
            ordinal_position,
            character_maximum_length
        FROM information_schema.columns
        WHERE table_schema NOT LIKE 'pg_%' 
        AND table_schema NOT LIKE 'information_schema'
        ORDER BY ordinal_position DESC;`;
    const request = {
      dbId: connectionId,
      query: SQL,
    };
    return api({ resource: "dbs", action: "query", request });
  },
);

export interface SchemaRow {
  table_schema: string;
  table_name: string;
  column_name: string;
  data_type: string;
  ordinal_position: number;
  character_maximum_length?: number;
}

export function parseSchema(rows: SchemaRow[] = []) {
  return nest("table_schema", rows).map((schema) => {
    const { field, list } = schema;
    return {
      field,
      list: nest("table_name", list).map(function (table) {
        table.list.sort(function (a, b) {
          return a.ordinal_position > b.ordinal_position ? 1 : -1;
        });
        return table;
      }),
    };
  });
}

function distinctTables(rows: SchemaRow[]) {
  let tableMap = rows.reduce(function (set, curr) {
    const { table_name, table_schema } = curr;
    let table = table_schema + "." + table_name;
    set.add(table);
    return set;
  }, new Set<string>());
  return tableMap;
}
export interface NestedParent<T> {
  field: string;
  list: T[];
}

export function nest<T>(field: keyof T, data: T[]) {
  const temp: Record<string, NestedParent<T>> = {};
  data.forEach(function (r) {
    let key = r[field] as unknown as string;
    if (!temp[key]) {
      temp[key] = {
        field: key,
        list: [],
      };
    }
    temp[key].list.push(r);
  });
  return Object.keys(temp).map(function (key) {
    return temp[key];
  });
}
interface Hint {
  hintType: "column" | "table";
  text: string;
  displayText: string;
  table: string;
}
function makeHints(rows: SchemaRow[]) {
  const columns: Hint[] = rows.map((row) => {
    const { column_name: t, table_name, table_schema } = row;
    const table = table_schema + "." + table_name;
    return {
      text: t,
      displayText: table_name + ": " + t,
      hintType: "column",
      table,
    };
  });
  const tables: Hint[] = [];
  distinctTables(rows).forEach(function (t) {
    tables.push({
      text: t,
      displayText: "tbl: " + t,
      hintType: "table",
      table: t,
    });
  });
  return { tables, columns };
}

function parseSchemaData(connectionId: string, rows: SchemaRow[]) {
  return {
    connectionId,
    raw_schemas: rows,
    schemas: [],
    hints: makeHints(rows),
  };
}
type ParsedSchema = ReturnType<typeof parseSchemaData>;
class MyCache<T> {
  key: string;
  constructor(key: string) {
    this.key = key;
  }
  remove() {
    localStorage.removeItem(this.key);
  }
  get(): T | null {
    try {
      const str = localStorage.getItem(this.key);
      if (!str) {
        return null;
      }
      return JSON.parse(str) as T;
    } catch (e) {
      this.remove();
      return null;
    }
  }
  set(value: T) {
    localStorage.setItem(this.key, JSON.stringify(value));
  }
}
const schemaCache = new MyCache<Record<string, SchemaRow[]>>("my_schemas");
(() => {
  const schemasList = schemaCache.get();
  if (!schemasList) {
    return null;
  }
  let schemas: Record<string, ParsedSchema> = {};
  for (let key in schemasList) {
    schemas[key] = parseSchemaData(key, schemasList[key]);
  }
  initialState.schemas = schemas;
})();

export const counterSlice = createSlice({
  name: "queries",
  initialState: { ...initialState },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    toggleListQueriesModal: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      state.listQueriesModal.open =
        action.payload ?? !state.listQueriesModal.open;
    },
    toggleRunningQueries: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      const newState = action.payload ?? !state.runningQueriesModal.open;
      state.runningQueriesModal.open = newState;
    },
    toggleQueryEditModal: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      let newState = action.payload ?? !state.queryEditModal.open;
      state.queryEditModal.open = newState;
      if (newState) {
        const tabId = state.activeQuery.tabId;
        const tab = state.tabs.data.find((t) => t._id === tabId);
        let query = state.queries.data.find((q) => q._id === tab?.queryId);
        if (!query) {
          query = {
            _id: "",
            adminTitle: "",
            tags: [],
            connectionId: tab?.connectionId || "REDSHIFT_MAKERIST",
            updatedAt: "",
            createdAt: new Date().toISOString(),
            sql: "",
          };
        }
        state.queryEditModal.query = query;
      }
    },
    toggleSchemas: (state, action: PayloadAction<boolean | undefined>) => {
      state.schemaSidebar.open = action.payload ?? !state.schemaSidebar.open;
    },
    setTabId: (state, action: PayloadAction<string>) => {
      state.activeQuery.tabId = action.payload;
    },
    addTab: (state, action: PayloadAction<Partial<QueryTab>>) => {
      const tab = {
        ...TAB(),
        ...action.payload,
      };
      state.tabs.data.push(tab);
      state.activeQuery.tabId = tab._id;
    },
    copyTab: (state, action: PayloadAction<string>) => {
      const activeTabId = state.activeQuery.tabId;
      const updateId = action.payload || activeTabId;
      let tab = state.tabs.data.find((t) => t._id == updateId);
      if (!tab) {
        return;
      }
      const newTab = {
        ...TAB(),
        connectionId: tab.connectionId,
        value: tab.value,
      };
      state.tabs.data.push(newTab);
      state.activeQuery.tabId = newTab._id;
    },
    closeTab: (state, action: PayloadAction<string>) => {
      const tabId = action.payload;
      state.tabs.data = state.tabs.data.filter(function (t) {
        return t._id !== tabId;
      });
      const newTabId = state.tabs.data.find((t) => t._id !== tabId)?._id || "";
      if (state.activeQuery.tabId === tabId) {
        state.activeQuery.tabId = newTabId;
      }
      api({
        resource: "queryTabs",
        action: "delete",
        request: {
          _id: tabId,
        },
      });
    },
    setTabValue: (state, action: PayloadAction<Partial<QueryTab>>) => {
      const data = action.payload;
      const activeTabId = state.activeQuery.tabId;
      const updateId = data?._id || activeTabId;
      let tab = state.tabs.data.find((t) => t._id == updateId) || TAB();
      Object.keys(data).forEach(function (key) {
        //@ts-ignore
        tab[key] = data[key];
      });
      cacheTabs(_.cloneDeep(tab));
    },
    setQueryValue: (state, action: PayloadAction<Partial<Query>>) => {
      const data = action.payload;
      Object.keys(data).forEach(function (key) {
        //@ts-ignore
        state.queryEditModal.query[key] = data[key];
      });
    },
    templateTab: (state, action: PayloadAction<string | undefined>) => {
      const activeTabId = state.activeQuery.tabId;
      const updateId = action.payload || activeTabId;
      let tab = state.tabs.data.find((t) => t._id == updateId) || TAB();
      tab.value = template(tab.value);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(runQuery.pending, (state, action) => {
      const { _id: tabId } = action.meta.arg;
      const { requestId } = action.meta;
      let tab = state.tabs.data.find((t) => t._id == tabId) || TAB();
      tab.error = "";
      tab.loading = true;
      delete state.runs[tab?.queryRunId || ""];
      tab.queryRunId = requestId;
    });
    builder.addCase(runQuery.fulfilled, (state, action) => {
      const { error, data: rows } = action.payload;
      const { requestId } = action.meta;
      const { _id: tabId } = action.meta.arg;
      let tab = state.tabs.data.find((t) => t._id == tabId) || TAB();
      if (requestId !== tab.queryRunId) {
        return;
      }
      tab.error = error || "";
      state.runs[requestId] = rows || [];
      tab.loading = false;
    });
    builder
      .addCase(downloadCSV.pending, (state, action) => {
        const { _id: tabId } = action.meta.arg;
        const { requestId } = action.meta;
        let tab = state.tabs.data.find((t) => t._id == tabId) || TAB();
        tab.error = "";
        tab.loading = true;
        tab.queryRunId = requestId;
      })
      .addCase(downloadCSV.fulfilled, (state, action) => {
        const { error } = action.payload;
        const { requestId } = action.meta;
        const { _id: tabId } = action.meta.arg;
        let tab = state.tabs.data.find((t) => t._id == tabId) || TAB();
        if (requestId !== tab.queryRunId) {
          return;
        }
        tab.error = error || "";
        tab.loading = false;
      });
    builder.addCase(loadSchema.fulfilled, (state, action) => {
      const connectionId = action.meta.arg;
      const { error, data } = action.payload;
      if (!data) {
        return;
      }
      state.schemas[connectionId] = parseSchemaData(connectionId, data);
      let schemas: Record<string, SchemaRow[]> = {};
      Object.keys(state.schemas).forEach(function (key) {
        schemas[key] = state.schemas[key].raw_schemas;
      });
      schemaCache.set(schemas);
    });
    builder
      .addCase(loadQueries.pending, (state, action) => {
        state.queries.loading = true;
        state.queries.error = "";
      })
      .addCase(loadQueries.fulfilled, (state, action) => {
        const { error, data } = action.payload;
        state.queries.loading = false;
        state.queries.error = error || "";
        state.queries.data = data || [];
      });
    builder.addCase(loadTab.fulfilled, (state, action) => {
      const tab = action.payload.data?.[0];
      if (!tab) {
        return;
      }
      let tabs = state.tabs.data.filter((t) => t._id !== tab?._id);
      tabs.push(tab);
      state.tabs.data = tabs;
      state.activeQuery.tabId = tab._id;
    });
    builder.addCase(loadTabCache.fulfilled, (state, action) => {
      for (let tab of action.payload) {
        if (state.tabs.data.find((t) => t._id === tab._id)) {
          continue;
        }
        state.tabs.data.push(tab);
      }
      state.activeQuery.tabId = action.payload[0]?._id;
    });
    builder
      .addCase(loadQuery.pending, (state, action) => {})
      .addCase(loadQuery.fulfilled, (state, action) => {
        const { error, data } = action.payload;
        let changed = 0;
        if (!data) {
          state.queries.error = error || "";
          return;
        }
        state.queries.data = state.queries.data.map(function (query) {
          if (query._id === data._id) {
            changed++;
            return { ...query, ...data };
          }
          return query;
        });
        if (!changed) {
          state.queries.data.push(data);
        }
      })
      .addCase(selectQuery.fulfilled, (state, action) => {
        const { error, data } = action.payload;
        let changed = 0;
        if (!data) {
          state.queries.error = error || "";
          return;
        }
        state.queries.data = state.queries.data.map(function (query) {
          if (query._id === data._id) {
            changed++;
            return { ...query, ...data };
          }
          return query;
        });
        if (!changed) {
          state.queries.data.push(data);
        }
        const tab = {
          ...TAB(),
          connectionId: data.connectionId,
          title: data.adminTitle,
          queryId: data._id,
          value: data.sql,
        };
        state.activeQuery.tabId = tab._id;
        state.tabs.data.push(tab);
      });
  },
});

export const {
  toggleListQueriesModal,
  setQueryValue,
  toggleQueryEditModal,
  toggleRunningQueries,
  toggleSchemas,
  templateTab,
  addTab,
  closeTab,
  setTabId,
  setTabValue,
  copyTab,
} = counterSlice.actions;

export default counterSlice.reducer;

import { useEffect } from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import AdminApp from "./AdminApp";
import { store } from "./redux/store";
import { ThemeProvider } from "./styles";
import {
  OAuthCallback,
  Login,
  AuthMFA,
  Logout,
  LoginRedirect,
} from "./routes/AuthRoutes";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { getUser } from "./redux/userSlice";

function AppRouting() {
  const isAuthed = useAppSelector((state) => !!state.user.user);
  const isLoading = useAppSelector((state) => state.user.status === "loading");
  const dispatch = useAppDispatch();
  useEffect(function () {
    dispatch(getUser());
  }, []);
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/logout" component={Logout} />
      <Route path="/auth/:provider/callback" component={OAuthCallback} />
      <Route path="/auth/mfa" component={AuthMFA} />
      <Route>
        {isLoading ? null : isAuthed ? <AdminApp /> : <LoginRedirect />}
      </Route>
    </Switch>
  );
}

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <AppRouting />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import usersSlice from './usersSlice';
import tasksSlice from './tasksSlice';
import queries from './queriesSlice';

export const store = configureStore({
  reducer: {
    queries,
    tasks: tasksSlice,
    user: userReducer,
    users: usersSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
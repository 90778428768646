import axios from "axios";

function paramsToObject(search) {
  let entries = new URLSearchParams(search).entries();
  let result = {};
  for (let entry of entries) {
    // each 'entry' is a [key, value] tupple
    let [key, value] = entry;
    if (value === "false") {
      value = false;
    }
    result[key] = value;
  }
  return result;
}

window.token = paramsToObject(window.location.search).token;
function post(url, payload) {
  if (window.token) {
    payload.token = window.token;
  }
  return axios.post(url, payload).then((res) => res.data);
}
function api(payload) {
  return post("/api/v0.1", payload);
}
window.api = api;
export { api, post };

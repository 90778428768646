import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { api } from '../utils/api';


export interface User {
    __v?:string,
    name?: string,
    requestMFAReset?: boolean,
    _id: string,
    permissions: 'all' | 'basic',
    email: string,
	lastLoginAt?: string
};

type Status = 'idle' | 'loading' | 'failed'

interface AsyncStatus {
    status:Status,
    complete:boolean
    error:string
}
function blankUser(): User{
    return {
        _id:'none',
        email:'',
        name:'',
        permissions:'basic',
    }
}
export interface UsersState {
  users:{
      status:Status,
      error: string,
      data:User[],
  },
  approveMFARequest:AsyncStatus;
  editUserModal:{
    error:string,
    status:Status,
    open:boolean,
    type:'add' | 'edit',
    user:User,
    isChanged: boolean
  }
}

const initialState: UsersState = {
    users: {
        status: 'idle',
        error:'',
        data:[]
    },
    approveMFARequest:{
        complete: false,
        status: 'idle',
        error:''
    },
    editUserModal:{
        error:'',
        status:'idle',
        open:false,
        type:'add',
        user:blankUser(),
        isChanged: false
    }
};
type Res<T> = { data:T, error:null } | { data:null, error:string };
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getUsers = createAsyncThunk<Res<User[]>>(
  'users/getUsers',
  async ()=>{
    let res = await api({
        resource:'users', action:'get'
    }) as Res<User[]>
    return res 
  }
)

export const upsertUser = createAsyncThunk<Res<User[]>>(
    'users/upsertUser',
    async (undefined, thunkAPI)=>{
        const state = thunkAPI.getState() as RootState 
        const {type, user, isChanged} = state.users.editUserModal
        if(!isChanged){
            return { error:'No changes', data:null }
        }
        const { _id,email,permissions,name } = user
		const request = {
            _id: type === 'add' ? undefined : _id,
            email,
            permissions,
            name
        }
		const { error } = await api({
			resource:'users', 
			action:type === 'add' ? 'create' : 'update',
			request
		})
        if(error){
            return {error, data:null}
        }
        let res = await api({
            resource:'users', action:'get'
        }) as Res<User[]>
        return res 
    }
)

export const deleteUser = createAsyncThunk<Res<User[]>>(
    'users/deleteUser',
    async (undefined, thunkAPI)=>{
        const state = thunkAPI.getState() as RootState 
        const {type, user } = state.users.editUserModal
        if(type === 'add'){
            return { error:'', data:null }
        }
        const { _id } = user
		const { error } = await api({
			resource:'users', 
			action:'delete',
			request:{_id}
		})
        if(error){
            return {error, data:null}
        }
        let res = await api({
            resource:'users', action:'get'
        }) as Res<User[]>
        return res 
    }
)

export const approveMFARequest = createAsyncThunk<Res<User[]>, string>(
    'users/approveMFARequest',
    async (userId: string)=>{
        const {error} = await api({
			resource:'auth',
			action:'resetMFAData',
			request:{ userId }
		}) as Res<'OK'>
        if(error){
            return { error, data:null }
        }
        let res = await api({
            resource:'users', action:'get'
        }) as Res<User[]>
        return res 
    }
)

export const counterSlice = createSlice({
  name: 'users',
  initialState:{ ...initialState },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    showEditUser: (state, action: PayloadAction<string>) => {
        const userId = action.payload
        const user = state.users.data.find(u=>u._id === userId)
        if(!user){
            return
        }
        state.editUserModal = {
            error:'',
            status:'idle',
            user,
            open:true,
            isChanged:false,
            type:'edit'
        }
    },
    setEditUser:( state, action: PayloadAction<Partial<User>>)=>{
        state.editUserModal.user = {
            ...state.editUserModal.user,
            ...action.payload
        }
        state.editUserModal.isChanged = true
    },
    showCreateUser: (state) => {
        state.editUserModal = {
            error:'',
            status:'idle',
            user:blankUser(),
            open:true,
            isChanged:false,
            type:'add'
        }
    },
    closeModal: (state)=>{
        state.editUserModal.open = false
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(upsertUser.pending, state=>{
        state.editUserModal.status = 'loading'
        state.editUserModal.error = ''
    }).addCase(upsertUser.fulfilled, (state, action)=>{
        state.users.status = 'idle'
        const { data:users, error } = action.payload
        if(error || !users){
            state.editUserModal.error = error || ''
            return
        }
        state.users.data = users?.map(function(u){
            delete u.__v
            return u
        })
        state.editUserModal.open = false
    })
    builder.addCase(deleteUser.pending, state=>{
        state.editUserModal.status = 'loading'
        state.editUserModal.error = ''
    }).addCase(deleteUser.fulfilled, (state, action)=>{
        state.users.status = 'idle'
        const { data:users, error } = action.payload
        if(error || !users){
            state.editUserModal.error = error || ''
            return
        }
        state.users.data = users?.map(function(u){
            delete u.__v
            return u
        })
        state.editUserModal.open = false
    })
    builder.addCase(getUsers.pending, state=>{
        state.users.status = 'loading'
    }).addCase(getUsers.fulfilled, (state, action)=>{
        state.users.status = 'idle'
        const { data:users, error } = action.payload
        if(error || !users){
            state.users.error = error || ''
            return
        }
        state.users.data = users?.map(function(u){
            delete u.__v
            return u
        })
    })
    builder
        .addCase(approveMFARequest.pending, (state)=>{
            state.approveMFARequest.status = 'loading'
            state.approveMFARequest.error = ''
        })
        .addCase(approveMFARequest.fulfilled, function(state, action){
            state.approveMFARequest.status = 'idle'
            const {data, error} = action.payload
            state.approveMFARequest.error = error || ''
            if(data instanceof Array){
                state.users.data = data
            }
        })
  },
});

export const { closeModal, showCreateUser, showEditUser, setEditUser } = counterSlice.actions;


export default counterSlice.reducer;

import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from './asyncComponent'

const Editor = asyncComponent(()=>import('./routes/Editor/index'));
const Tasks = asyncComponent(()=>import('./routes/Tasks/index'));
const TaskOverview = asyncComponent(()=>import('./routes/TaskOverview'));
const Users = asyncComponent(()=>import('./routes/Users'));
const NavBar = asyncComponent(()=>import('./layout/Navbar'));

export default function AdminApp(){
	return <Route>
	  	<NavBar/>
	    <Switch>
			<Route path='/app/tasks' exact component={Tasks} />
			<Route path='/app/tasks/overview' exact component={TaskOverview} />
			<Route path='/app/users' exact component={Users}/>
			<Route path='/app/editor/:tabId' component={Editor}/>
			<Redirect to='/app/editor/new-tab'/>
	    </Switch>
    </Route>
}




import {emit} from '../../utils/sockets'


export function selectTask(taskName: string){
    emit('tasks','calculateTree',{ taskName })
    emit('tasks','logs',{ taskName })
}

export function tierNodes(list: any[]){
    let edges: any[] = [];
    let d: Record<string, any> = {};
    let pvt: Record<string, any> = {};
    list.filter(n=>!/NOT_TO_BE/.test(n.label)).forEach(function(node) {
        let {tier,id} = node;
        let t: string = tier.toString();
        let n: string = (tier + 1).toString()
        if(!d[t]){
            d[t] = []
        }
        node.tierRun = node.tier+'b'
        d[t].push(node);
        pvt[t] = pvt[t] ? pvt[t]:  {
            id:t,
            label:'TIER '+t,
            color:'yellow',
            tierRun:t+'a'
        }
        pvt[n] = pvt[n] ? pvt[n] : {
            id:n,
            label:'TIER '+n,
            color:'yellow',
            tierRun:n+'a'
        };
        edges = edges.concat([
            {to:id, from:t, o:t},
            {to:n, from:id, o:n }
        ]);
    });
    let nodes: any[] = [];
    for(let k in d){
        nodes = nodes.concat(d[k])
    }
    for(let k in pvt){
        nodes.push(pvt[k]);
    }
    nodes.sort((a,b)=>a.tierRun+a.id > b.tierRun+b.id ? 1 : -1);
    edges.sort((a,b)=>a.o > b.o ? 1 : -1)
    return {
        nodes,
        edges
    }
}

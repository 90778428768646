import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { api } from '../utils/api';
import socket from '../utils/sockets'

interface User {
  name?: string,
  requestMFAReset?: boolean,
  _id: string,
  id: string,
  permissions: 'all' | 'basic',
  email: string
};
interface MFA {
	dataURL?:string,
	base32?:string,
  mfaId?:string
}
type Status = 'idle' | 'loading' | 'failed'

interface AsyncStatus {
  status:Status,
  complete:boolean
  error:string
}
export interface UserState {
  auth: null | string;
  requestMFAReset:AsyncStatus,
  logout:AsyncStatus,
  status: Status;
  error: null | string,
  user: null | User;
  mfa: null | MFA
}

const initialState: UserState = {
	auth:null,
  status:'idle',
  requestMFAReset:{
    status:'idle',
    complete:false,
    error:''
  },
  logout:{
    status:'idle',
    complete:false,
    error:''
  },
  error:null,
	user:null,
	mfa:null
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const logout = createAsyncThunk(
  'user/logout',
  async ()=>{
    await api({
      resource:'auth',
      action:'logout',
      request:{}
    })
  }
)
export const getUser = createAsyncThunk(
  'user/getUser',
  async () => {
    const res = await api({
        resource:'auth',
        action:'getMe'
    });
    // The value we return becomes the `fulfilled` action payload
    return res.data;
  }
)

export const completeMFALogin = createAsyncThunk(
  'user/completeMFALogin',
  async (code:string, thunkAPI) =>{
    const state = thunkAPI.getState() as RootState
    const res = await api({
      resource:'auth',
      action:'completeMFALogin',
      request:{
        ...state.user.mfa,
        code
      }
    })
    if(res.data){
      // this resets the auth details associated with the socket
      socket.emit('login', {
        token:res.data.id
      })
    }
    return res
  }
)

export const requestMFAReset = createAsyncThunk(
  'user/requestMFAReset',
  async (mfaId: string)=>{
    const {error} = await api({
      resource:'auth',
      action:'requestMFAReset',
      request:{mfaId}
    })
    return error || null
  }
)


export const counterSlice = createSlice({
  name: 'user',
  initialState:{ ...initialState },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setMFA: (state, action: PayloadAction<MFA>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.mfa = action.payload
    },
    setUser: (state, action: PayloadAction<User | null>) => {
      const user = action.payload
      socket.emit('login',{
        token:user?.id || undefined
      })
      state.user = user
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(logout.pending, state=>{
      state.logout.status = 'loading'
    }).addCase(logout.fulfilled, (state)=>{
      state.logout.status = 'idle'
    })
    builder.addCase(requestMFAReset.pending, state=>{
      state.requestMFAReset.status = 'loading'
    }).addCase(requestMFAReset.fulfilled, (state, action)=>{
      const error = action.payload
      state.requestMFAReset.status = 'idle'
      state.requestMFAReset.complete = !error
      state.requestMFAReset.error = error || ''
    })
    builder
      .addCase(completeMFALogin.pending, (state)=>{
        state.status = 'loading'
      })
      .addCase(completeMFALogin.fulfilled, (state, action)=>{
        const {data:user, error} = action.payload
        if(user){
          state.user = user
        }else{
          state.error = error
        }
        state.status = 'idle'
      })
    builder
      .addCase(getUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = action.payload;
      });


/// SPECIAL Matches
      builder.addMatcher((action)=>{
        const {type,payload} = action
        return type.includes('fulfilled') && payload?.error === 'PERMISSION_DENIED'
      }, (state, action)=>{
        state.user = null
      })
  },
});

export const { setMFA, setUser } = counterSlice.actions;


export default counterSlice.reducer;
